import AppStore from "./AppStore";
import { buildClassName } from "./util";

// id: "ff89aa2e-02c2-4219-bd02-61dc713fa33b"
// title: "Header Area Top"
// type: :html
// key: "default-header-top"
// parent_id: nil
export function DefaultHeaderTopFf89aa2e02c24219Bd0261dc713fa33b(props: any) {
  const Link = AppStore.presets["Link"];
  const PrimaryNavigation = AppStore.presets["PrimaryNavigation"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} DefaultHeaderTopFf89aa2e02c24219Bd0261dc713fa33b header-top`}>
      {props["linked-logo"] || <Link parentTag={buildClassName("LinkedLogoDd09f2acDe794fa9A598Ce1cb114e5f6", parentTag)} label={<DefaultLogo898dba46C1754a6991f823b95a39a06f parentTag="Label2f3368d5Be8b4ec5Bf02D3263981acff" alt="logo" />} url="/" className="linked-logo" {...props} />}
      {props["navigation"] || <PrimaryNavigation parentTag={buildClassName("Navigation0730e74e76e4483cB0e450a8efd88835", parentTag)} navMenuSlug="marketing-primary-nav" className="navigation" {...props} />}
    </div>
  );
}

// id: "e8fa3b70-9fbc-4162-8009-be89e3c2d4bc"
// title: "Members Area Header"
// type: :reference
// key: "members-area-header"
// parent_id: nil
export function MembersAreaHeaderE8fa3b709fbc41628009Be89e3c2d4bc(props: any) {
  return (
    <ShortHeader00e74f76B0314fbb852b23e9a5ca18eb parentTag="MembersAreaHeaderE8fa3b709fbc41628009Be89e3c2d4bc" header-top={<DefaultHeaderTopFf89aa2e02c24219Bd0261dc713fa33b parentTag="HeaderTop43e1101e01c74852A28042c347142d49" navMenuSlug="members-primary-nav" />} {...props} />
  );
}

// id: "37564e7d-2e5c-4026-b341-786c49947cac"
// title: "Resources Page"
// type: :reference
// key: "resources-page"
// parent_id: nil
export function ResourcesPage37564e7d2e5c4026B341786c49947cac(props: any) {
  return (
    <SecondaryPage88e97e86403c48d6911354ad91970a52 parentTag="ResourcesPage37564e7d2e5c4026B341786c49947cac" header={<SecondaryMarketingHeaderA2013df6Cc29458b8bd86b53c30a642b parentTag="Header4586270f41a045d6947c5aa76474c8ea" title="Resources" />} {...props} />
  );
}

// id: "b0430b17-1106-44ff-89ab-3b59298a77dd"
// title: "About Us - Post Slug"
// type: :text
// key: "postSlug"
// parent_id: nil
export const PostSlugB0430b17110644ff89ab3b59298a77dd = "about-us-posts";

// id: "4072b525-54af-4750-a2a5-35d5874f3ac4"
// title: "Marketing Cards Body"
// type: :html
// key: "marketing-body"
// parent_id: nil
export function MarketingBody4072b52554af4750A2a535d5874f3ac4(props: any) {
  const MarketingCards = AppStore.presets["MarketingCards"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} MarketingBody4072b52554af4750A2a535d5874f3ac4 page-body`}>
      {props["marketing-cards"] || <MarketingCards parentTag={buildClassName("MarketingCardsB8f8cae766754d75925693532adfaddc", parentTag)} {...props} />}
    </div>
  );
}

// id: "9b026caf-9791-4190-8750-1e649c7950c9"
// title: "Password Reset Page"
// type: :reference
// key: "password-reset-page"
// parent_id: nil
export function PasswordResetPage9b026caf9791419087501e649c7950c9(props: any) {
  return (
    <LoginPage31818322Fff04ed68518Eeef698bf98d parentTag="PasswordResetPage9b026caf9791419087501e649c7950c9" {...props} />
  );
}

// id: "483a0cab-f1c5-451e-b925-a26f01f01c2b"
// title: "Label"
// type: :reference
// key: "label"
// parent_id: nil
export function Label483a0cabF1c5451eB925A26f01f01c2b(props: any) {
  return (
    <DefaultLogo898dba46C1754a6991f823b95a39a06f parentTag="Label483a0cabF1c5451eB925A26f01f01c2b" {...props} />
  );
}

// id: "fb812ed6-61eb-47f0-9fa1-50fcc87dc320"
// title: "Message Pages"
// type: :html
// key: "message-pages"
// parent_id: nil
export function MessagePagesFb812ed661eb47f09fa150fcc87dc320(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} MessagePagesFb812ed661eb47f09fa150fcc87dc320`}>
      {props["header"] || <MembersAreaHeaderE8fa3b709fbc41628009Be89e3c2d4bc parentTag="HeaderBc40802b5eeb4ee1853392cafe603ff9" {...props} />}
      {props.children}
    </div>
  );
}

// id: "00302df1-2a44-45b4-986c-e7e9d0fe2b45"
// title: "Join Us Page"
// type: :reference
// key: "join-us-page"
// parent_id: nil
export function JoinUsPage00302df12a4445b4986cE7e9d0fe2b45(props: any) {
  return (
    <SecondaryPage88e97e86403c48d6911354ad91970a52 parentTag="JoinUsPage00302df12a4445b4986cE7e9d0fe2b45" header={<SecondaryMarketingHeaderA2013df6Cc29458b8bd86b53c30a642b parentTag="Header64b483efEdb346dbB5333d7dfe279129" title="Join Us" />} {...props} />
  );
}

// id: "b8a0f2c9-2fae-421c-9441-c18b0a322004"
// title: "Common Footer"
// type: :reference
// key: "footer"
// parent_id: nil
export function FooterB8a0f2c92fae421c9441C18b0a322004(props: any) {
  return (
    <DefaultFooterF5ee257f7f8b488eA8162e51e9c705dc parentTag="FooterB8a0f2c92fae421c9441C18b0a322004" {...props} />
  );
}

// id: "f87d1996-9fdf-45b2-9ac7-f27efa16addf"
// title: "Members Area"
// type: :html
// key: "members-area-default"
// parent_id: nil
export function MembersAreaDefaultF87d19969fdf45b29ac7F27efa16addf(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} MembersAreaDefaultF87d19969fdf45b29ac7F27efa16addf page`}>
      {props["header"] || <MembersAreaHeaderE8fa3b709fbc41628009Be89e3c2d4bc parentTag="Header1e2af36eA86e4a5cA0480a554072cb1d" navMenuSlug="members-primary-nav" {...props} />}
      {props["footer"] || <DefaultFooterF5ee257f7f8b488eA8162e51e9c705dc parentTag="Footer05f48960Be1e40ddB100D3ba346c1cf4" {...props} />}
    </div>
  );
}

// id: "c6154e6c-4c0d-4773-a746-d098c17e3649"
// title: "About Us"
// type: :html
// key: "about-us-page"
// parent_id: nil
export function AboutUsPageC6154e6c4c0d4773A746D098c17e3649(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} AboutUsPageC6154e6c4c0d4773A746D098c17e3649 page`}>
      {props["header"] || <SecondaryMarketingHeaderA2013df6Cc29458b8bd86b53c30a642b parentTag="HeaderE5af14be213d498b8c2d3314baa8b215" title="About Us" {...props} />}
      {props["body"] || <MarketingBody4072b52554af4750A2a535d5874f3ac4 parentTag="Body5272ff643ed74c77A39c774f1341a73e" postSlug="about-page-posts" {...props} />}
      {props["footer"] || <DefaultFooterF5ee257f7f8b488eA8162e51e9c705dc parentTag="Footer05e4276eF18b4812A8857f50a1096206" {...props} />}
    </div>
  );
}

// id: "a2013df6-cc29-458b-8bd8-6b53c30a642b"
// title: "Secondary Marketing Header"
// type: :html
// key: "secondary-marketing-header"
// parent_id: nil
export function SecondaryMarketingHeaderA2013df6Cc29458b8bd86b53c30a642b(props: any) {
  const { parentTag, ...rest } = props;
  props = { title: "Secondary Marketing Title 1", ...rest };
  return (
    <div className={`${parentTag || ""} SecondaryMarketingHeaderA2013df6Cc29458b8bd86b53c30a642b site-header`}>
      <div className="background-color" />
      <div className="background-image" />
      <div className="header-top-wrapper">
        <div className="header-top-opacity">
        </div>
        {props["header-top"] || <DefaultHeaderTopFf89aa2e02c24219Bd0261dc713fa33b parentTag="HeaderTop031bfc107975485dB378231c97aefdc2" {...props} />}
      </div>
      {props["hero"] || <SecondaryMarketingHero7bdcdb8a8b3745ad9719Ffff926f91d0 parentTag="Hero235c7998C31249d7Bd334c04e3251110" title="Secondary Marketing Title 2" {...props} />}
    </div>
  );
}

// id: "76e5e318-2632-4b71-ba45-cbc1b69483e6"
// title: ""
// type: :text
// key: "buttonClass"
// parent_id: "af6d5bba-4c1a-4003-96a5-d0403b626ced"
export const ButtonClass76e5e31826324b71Ba45Cbc1b69483e6 = "inline-block px-12 py-3 hover:brightness-110 transition duration";

// id: "dd09f2ac-de79-4fa9-a598-ce1cb114e5f6"
// title: ""
// type: :gf_preset
// key: "linked-logo"
// parent_id: "ff89aa2e-02c2-4219-bd02-61dc713fa33b"
export function LinkedLogoDd09f2acDe794fa9A598Ce1cb114e5f6(props: any) {
  const Link = AppStore.presets["Link"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <Link parentTag={buildClassName("LinkedLogoDd09f2acDe794fa9A598Ce1cb114e5f6", parentTag)} label={<DefaultLogo898dba46C1754a6991f823b95a39a06f parentTag="Label2f3368d5Be8b4ec5Bf02D3263981acff" alt="logo" />} url="/" className="linked-logo" {...props} />
  );
}

// id: "7a569048-aa30-46e6-b58b-02fac3bdedcf"
// title: ""
// type: :text
// key: "alt"
// parent_id: "898dba46-c175-4a69-91f8-23b95a39a06f"
export const Alt7a569048Aa3046e6B58b02fac3bdedcf = "logo";

// id: "84fb6d78-6c02-485d-bd8b-6a586b669b41"
// title: ""
// type: :reference
// key: "header"
// parent_id: "aae75d21-fdb0-46a4-8ba8-330ee3921424"
export function Header84fb6d786c02485dBd8b6a586b669b41(props: any) {
  return (
    <SecondaryMarketingHeaderA2013df6Cc29458b8bd86b53c30a642b parentTag="Header84fb6d786c02485dBd8b6a586b669b41" title="Public Events" {...props} />
  );
}

// id: "8eecb1e6-a1d4-49cf-8787-c7c541936ae6"
// title: ""
// type: :gf_preset
// key: "linked-logo"
// parent_id: "00e74f76-b031-4fbb-852b-23e9a5ca18eb"
export function LinkedLogo8eecb1e6A1d449cf8787C7c541936ae6(props: any) {
  const Link = AppStore.presets["Link"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <Link parentTag={buildClassName("LinkedLogo8eecb1e6A1d449cf8787C7c541936ae6", parentTag)} label={<DefaultLogo898dba46C1754a6991f823b95a39a06f parentTag="LabelCedb3851D3034fa9B29d1267b5ad7c0a" alt="logo" />} {...props} />
  );
}

// id: "1e2af36e-a86e-4a5c-a048-0a554072cb1d"
// title: ""
// type: :reference
// key: "header"
// parent_id: "f87d1996-9fdf-45b2-9ac7-f27efa16addf"
export function Header1e2af36eA86e4a5cA0480a554072cb1d(props: any) {
  return (
    <MembersAreaHeaderE8fa3b709fbc41628009Be89e3c2d4bc parentTag="Header1e2af36eA86e4a5cA0480a554072cb1d" navMenuSlug="members-primary-nav" {...props} />
  );
}

// id: "6d0b44d4-0418-47a7-826a-91a32870303a"
// title: ""
// type: :html
// key: "logo"
// parent_id: "f5ee257f-7f8b-488e-a816-2e51e9c705dc"
export function Logo6d0b44d4041847a7826a91a32870303a(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <img src="https://mp1md-pub.s3.amazonaws.com/orgs/demo-2/logo-wide-vector.svg" alt="logo" className={`${parentTag || ""} Logo6d0b44d4041847a7826a91a32870303a logo`} />
  );
}

// id: "2f3368d5-be8b-4ec5-bf02-d3263981acff"
// title: ""
// type: :reference
// key: "label"
// parent_id: "dd09f2ac-de79-4fa9-a598-ce1cb114e5f6"
export function Label2f3368d5Be8b4ec5Bf02D3263981acff(props: any) {
  return (
    <DefaultLogo898dba46C1754a6991f823b95a39a06f parentTag="Label2f3368d5Be8b4ec5Bf02D3263981acff" {...props} />
  );
}

// id: "b361efb6-94b5-45e7-869d-692ae4051c1e"
// title: ""
// type: :text
// key: "title"
// parent_id: "84fb6d78-6c02-485d-bd8b-6a586b669b41"
export const TitleB361efb694b545e7869d692ae4051c1e = "Public Events";

// id: "af6d5bba-4c1a-4003-96a5-d0403b626ced"
// title: "Marketing Cards"
// type: :gf_preset
// key: "marketing-cards"
// parent_id: nil
export function MarketingCardsAf6d5bba4c1a400396a5D0403b626ced(props: any) {
  const MarketingCards = AppStore.presets["MarketingCards"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <MarketingCards parentTag={buildClassName("MarketingCardsAf6d5bba4c1a400396a5D0403b626ced", parentTag)} buttonClass="inline-block px-12 py-3 hover:brightness-110 transition duration" {...props} />
  );
}

// id: "4db48201-8d69-4349-b85a-1212e0e56dc1"
// title: "Page Quote"
// type: :html
// key: "page-quote"
// parent_id: nil
export function PageQuote4db482018d694349B85a1212e0e56dc1(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} PageQuote4db482018d694349B85a1212e0e56dc1`}>
      <div>
      </div>
      <div>
      </div>
    </div>
  );
}

// id: "00e74f76-b031-4fbb-852b-23e9a5ca18eb"
// title: "Short Header"
// type: :html
// key: "short-header"
// parent_id: nil
export function ShortHeader00e74f76B0314fbb852b23e9a5ca18eb(props: any) {
  const Link = AppStore.presets["Link"];
  const PrimaryNavigation = AppStore.presets["PrimaryNavigation"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} ShortHeader00e74f76B0314fbb852b23e9a5ca18eb site-header`}>
      <div className="background-image" />
      <div className="header-top">
        {props["linked-logo"] || <Link parentTag={buildClassName("LinkedLogo8eecb1e6A1d449cf8787C7c541936ae6", parentTag)} label={<DefaultLogo898dba46C1754a6991f823b95a39a06f parentTag="LabelCedb3851D3034fa9B29d1267b5ad7c0a" alt="logo" />} {...props} />}
        {props["navigation"] || <PrimaryNavigation parentTag={buildClassName("Navigation2ac18fdaD4364d9e9cd1548dfafe51df", parentTag)} navMenuSlug="members-primary-nav" {...props} />}
      </div>
    </div>
  );
}

// id: "235c7998-c312-49d7-bd33-4c04e3251110"
// title: ""
// type: :reference
// key: "hero"
// parent_id: "a2013df6-cc29-458b-8bd8-6b53c30a642b"
export function Hero235c7998C31249d7Bd334c04e3251110(props: any) {
  return (
    <SecondaryMarketingHero7bdcdb8a8b3745ad9719Ffff926f91d0 parentTag="Hero235c7998C31249d7Bd334c04e3251110" title="Secondary Marketing Title 2" {...props} />
  );
}

// id: "64b483ef-edb3-46db-b533-3d7dfe279129"
// title: ""
// type: :reference
// key: "header"
// parent_id: "00302df1-2a44-45b4-986c-e7e9d0fe2b45"
export function Header64b483efEdb346dbB5333d7dfe279129(props: any) {
  return (
    <SecondaryMarketingHeaderA2013df6Cc29458b8bd86b53c30a642b parentTag="Header64b483efEdb346dbB5333d7dfe279129" title="Join Us" {...props} />
  );
}

// id: "ca2be9ab-d940-47df-b254-b6615a7817e5"
// title: ""
// type: :reference
// key: "header"
// parent_id: "ef6a4657-43ea-4a4b-825e-82bb3e33a2fb"
export function HeaderCa2be9abD94047dfB254B6615a7817e5(props: any) {
  return (
    <HomeHeaderDa36af7f51c0441aBff2Bc70ced6e2be parentTag="HeaderCa2be9abD94047dfB254B6615a7817e5" {...props} />
  );
}

// id: "05e4276e-f18b-4812-a885-7f50a1096206"
// title: ""
// type: :reference
// key: "footer"
// parent_id: "c6154e6c-4c0d-4773-a746-d098c17e3649"
export function Footer05e4276eF18b4812A8857f50a1096206(props: any) {
  return (
    <DefaultFooterF5ee257f7f8b488eA8162e51e9c705dc parentTag="Footer05e4276eF18b4812A8857f50a1096206" {...props} />
  );
}

// id: "7bcec08c-edc5-44a3-94ef-ff49914af486"
// title: ""
// type: :html
// key: "social-links"
// parent_id: "f5ee257f-7f8b-488e-a816-2e51e9c705dc"
export function SocialLinks7bcec08cEdc544a394efFf49914af486(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} SocialLinks7bcec08cEdc544a394efFf49914af486 flex flex-row justify-center gap-6 flex flex-row justify-center gap-6`}>
      <a className="hover:brightness-110" href="https://www.instagram.com/sexposla/">
        <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg" title="Instagram icon" className="fill-current" style={{width: "30px", height: "30px"}}>
          <path d="M18.5769 0C8.34616 0 0 8.34616 0 18.5769V45.4423C0 55.6538 8.34616 64 18.5769 64H45.4423C55.6538 64 64 55.6539 64 45.4231V18.5769C64 8.34616 55.6539 0 45.4231 0H18.5769ZM18.5769 4.92308H45.4231C53 4.92308 59.0769 11 59.0769 18.5769V45.4231C59.0769 53 53 59.0769 45.4423 59.0769H18.5769C11 59.0769 4.92308 53 4.92308 45.4423V18.5769C4.92308 11 11 4.92308 18.5769 4.92308ZM51.6923 9.84615C50.3269 9.84615 49.2308 10.9423 49.2308 12.3077C49.2308 13.6731 50.3269 14.7692 51.6923 14.7692C53.0577 14.7692 54.1538 13.6731 54.1538 12.3077C54.1538 10.9423 53.0577 9.84615 51.6923 9.84615ZM32 14.7692C22.5192 14.7692 14.7692 22.5192 14.7692 32C14.7692 41.4808 22.5192 49.2308 32 49.2308C41.4808 49.2308 49.2308 41.4808 49.2308 32C49.2308 22.5192 41.4808 14.7692 32 14.7692ZM32 19.6923C38.8269 19.6923 44.3077 25.1731 44.3077 32C44.3077 38.8269 38.8269 44.3077 32 44.3077C25.1731 44.3077 19.6923 38.8269 19.6923 32C19.6923 25.1731 25.1731 19.6923 32 19.6923Z" />
        </svg>
      </a>
      <a className="hover:brightness-110" href="https://www.facebook.com/groups/1104997742859454">
        <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg" title="Facebook icon" className="fill-current" style={{width: "30px", height: "30px"}}>
          <path d="M32 0C14.356 0 0 14.356 0 32C0 49.644 14.356 64 32 64C49.644 64 64 49.644 64 32C64 14.356 49.644 0 32 0ZM32 4.92308C46.9833 4.92308 59.0769 17.0167 59.0769 32C59.0769 45.6189 49.0711 56.8173 36 58.75V39.8269H43.6442L44.8462 32.0673H36V27.8173C36 24.5927 37.0495 21.7308 40.0673 21.7308H44.9135V14.9519C44.0618 14.8387 42.2625 14.5865 38.8558 14.5865C31.7468 14.5865 27.5769 18.3428 27.5769 26.8942V32.0577H20.2692V39.8269H27.5865V58.6827C14.719 56.5766 4.92308 45.4748 4.92308 32C4.92308 17.0167 17.0167 4.92308 32 4.92308Z" />
        </svg>
      </a>
      <a className="hover:brightness-110" href="https://twitter.com/sexposla">
        <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg" title="Twitter icon" className="fill-current" style={{width: "30px", height: "30px"}}>
          <path d="M8 0C3.61317 0 0 3.61317 0 8V56C0 60.3868 3.61317 64 8 64H56C60.3868 64 64 60.3868 64 56V8C64 3.61317 60.3868 0 56 0H8ZM8 5.33333H56C57.5012 5.33333 58.6667 6.49883 58.6667 8V56C58.6667 57.5012 57.5012 58.6667 56 58.6667H8C6.49883 58.6667 5.33333 57.5012 5.33333 56V8C5.33333 6.49883 6.49883 5.33333 8 5.33333ZM41.25 16C36.754 16 33.0988 19.6552 33.1042 24.1458C33.1042 24.9832 33.3958 25.5442 33.3958 26.1042C26.6545 25.8215 21.0557 22.4608 17.125 17.6875C16.2823 18.8128 16 20.2119 16 21.6146C16 24.4199 17.0931 26.6674 19.3438 28.6354C17.8504 28.4328 17.1253 28.0704 16 27.5104C16 31.4411 18.5029 34.527 22.1563 35.375C22.1563 35.375 20.7489 35.6563 19.9063 35.6563C19.2663 35.6563 18.5 35.375 18.5 35.375C19.6253 38.463 22.43 40.9896 26.0833 40.9896C23.278 42.9576 19.6221 44.3542 15.9688 44.3542H14C16.6667 46.6688 20.6635 48 26.3542 48C41.2395 48 49.3958 35.6563 49.3958 24.9896V23.8646C50.8038 22.7393 52.2027 21.3365 53.3333 19.6458C51.648 20.4938 50.2428 20.7694 48.5521 21.0521C50.2374 19.9321 51.6483 18.5252 52.2083 16.5625C50.8057 17.3998 49.1243 18.2382 47.1563 18.5208C45.7483 16.8408 43.5007 16 41.25 16Z" />
        </svg>
      </a>
      <a className="hover:brightness-110" href="https://www.sexpositivelosangeles.org/resources/discord">
        <svg width="64" height="71" viewBox="0 0 64 71" fill="none" xmlns="http://www.w3.org/2000/svg" title="Discord icon" className="fill-current" style={{width: "30px", height: "30px"}}>
          <path d="M7.11111 0C3.22467 0 0 3.19494 0 7.04555V56.3644C0 60.2127 3.22079 63.4062 7.10417 63.4099L48.6875 63.5063C49.2395 63.5064 49.784 63.3792 50.2778 63.1347L58.2014 69.506C58.7228 69.9259 59.3537 70.1908 60.0208 70.27C60.688 70.3493 61.3641 70.2396 61.971 69.9538C62.5778 69.6679 63.0905 69.2175 63.4496 68.6549C63.8087 68.0922 63.9995 67.4402 64 66.7745V7.04555C64 3.19494 60.7753 0 56.8889 0H7.11111ZM7.11111 7.04555H56.8889V59.3711L50.1667 53.97C49.6617 53.5648 49.0543 53.3044 48.4104 53.2172C47.7666 53.1299 47.1109 53.2191 46.5146 53.4752C45.9184 53.7312 45.4043 54.1442 45.0283 54.6693C44.6523 55.1945 44.4288 55.8117 44.3819 56.4538L7.11806 56.3644C7.11574 56.3644 7.11343 56.3644 7.11111 56.3644V7.04555ZM27.1528 18.1643C22.559 18.6011 19.0694 21.3224 19.0694 21.3224C19.0694 21.3224 14.9333 27.2306 14.2222 38.7712C18.4036 43.5058 24.7292 43.5324 24.7292 43.5324L26.0417 41.7985C24.7012 41.3406 23.5587 40.7531 22.3889 39.8583L22.6319 39.1427C24.8186 40.1326 27.6267 40.7114 32 40.7114C36.3733 40.7114 39.1814 40.1291 41.3681 39.1427L41.6111 39.8583C40.4378 40.7566 39.2952 41.3441 37.9583 41.7985L39.2708 43.5324C39.2708 43.5324 45.5964 43.5058 49.7778 38.7712C49.0667 27.2306 44.9306 21.3224 44.9306 21.3224C44.9306 21.3224 41.2277 18.4884 36.8472 18.1643L35.9444 19.9945C34.604 19.7514 33.2053 19.5748 32 19.5748C30.7556 19.5748 29.3572 19.7415 28.0417 19.967L27.1528 18.1643ZM25.5972 28.1822C27.1617 28.1822 28.4444 29.7604 28.4444 31.705C28.4444 33.6495 27.1617 35.2277 25.5972 35.2277C24.0328 35.2277 22.7569 33.6495 22.7569 31.705C22.7569 29.7604 24.0328 28.1822 25.5972 28.1822ZM38.4028 28.1822C39.9672 28.1822 41.2431 29.7604 41.2431 31.705C41.2431 33.6495 39.9672 35.2277 38.4028 35.2277C36.8383 35.2277 35.5556 33.6495 35.5556 31.705C35.5556 29.7604 36.8383 28.1822 38.4028 28.1822Z" />
        </svg>
      </a>
    </div>
  );
}

// id: "aa589c05-590c-4015-b146-235f6d25a527"
// title: ""
// type: :reference
// key: "header-top"
// parent_id: "da36af7f-51c0-441a-bff2-bc70ced6e2be"
export function HeaderTopAa589c05590c4015B146235f6d25a527(props: any) {
  return (
    <DefaultHeaderTopFf89aa2e02c24219Bd0261dc713fa33b parentTag="HeaderTopAa589c05590c4015B146235f6d25a527" {...props} />
  );
}

// id: "834df52c-bada-4829-a664-79f306cf4885"
// title: ""
// type: :text
// key: "url"
// parent_id: "dd09f2ac-de79-4fa9-a598-ce1cb114e5f6"
export const Url834df52cBada4829A66479f306cf4885 = "/";

// id: "d994d57f-a8a7-4769-9dde-8760c76fd8ba"
// title: ""
// type: :text
// key: "title"
// parent_id: "cb2104c4-d954-4c57-ae25-6a34f9012b9b"
export const TitleD994d57fA8a747699dde8760c76fd8ba = "Join Us";

// id: "8c387e70-0ead-4706-a9a3-181272fd7321"
// title: ""
// type: :text
// key: "class"
// parent_id: "dd09f2ac-de79-4fa9-a598-ce1cb114e5f6"
export const Class8c387e700ead4706A9a3181272fd7321 = "linked-logo";

// id: "6e94536b-4585-4a65-b275-4b20df9e3629"
// title: "Signup Pages"
// type: :reference
// key: "signup-pages"
// parent_id: nil
export function SignupPages6e94536b45854a65B2754b20df9e3629(props: any) {
  return (
    <SecondaryPage88e97e86403c48d6911354ad91970a52 parentTag="SignupPages6e94536b45854a65B2754b20df9e3629" header={<SecondaryMarketingHeaderA2013df6Cc29458b8bd86b53c30a642b parentTag="HeaderCb2104c4D9544c57Ae256a34f9012b9b" title="Join Us" />} {...props} />
  );
}

// id: "c9bcf323-2ed0-46b7-90cd-c756a5dea53c"
// title: "HREF"
// type: :text
// key: "href"
// parent_id: nil
export const HrefC9bcf3232ed046b790cdC756a5dea53c = "/";

// id: "f5ee257f-7f8b-488e-a816-2e51e9c705dc"
// title: "Footer"
// type: :html
// key: "default-footer"
// parent_id: nil
export function DefaultFooterF5ee257f7f8b488eA8162e51e9c705dc(props: any) {
  const Navigation = AppStore.presets["Navigation"];
  const GroupCopyright = AppStore.presets["GroupCopyright"];
  const GroupFlowTag = AppStore.presets["GroupFlowTag"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} DefaultFooterF5ee257f7f8b488eA8162e51e9c705dc footer`}>
      {props["social-links"] || <SocialLinks604036b3999548b2804620bfc75b383f />}
      {props["links"] || <Navigation parentTag={buildClassName("LinksD7610a2cA750465c8f5fE9bdbe2f2fd2", parentTag)} navMenuSlug="footer-nav" {...props} />}
      <div className="logo-wrapper">
        {props["logo"] || <Logo6d0b44d4041847a7826a91a32870303a />}
      </div>
      {props["copyright"] || <GroupCopyright parentTag={buildClassName("CopyrightA2e3538b9064476aA9626f71ca2064fe", parentTag)} {...props} />}
      {props["groupflow"] || <GroupFlowTag parentTag={buildClassName("Groupflow51a5a35557fd406dBa19076d6811c3a8", parentTag)} {...props} />}
    </div>
  );
}

// id: "e5af14be-213d-498b-8c2d-3314baa8b215"
// title: ""
// type: :reference
// key: "header"
// parent_id: "c6154e6c-4c0d-4773-a746-d098c17e3649"
export function HeaderE5af14be213d498b8c2d3314baa8b215(props: any) {
  return (
    <SecondaryMarketingHeaderA2013df6Cc29458b8bd86b53c30a642b parentTag="HeaderE5af14be213d498b8c2d3314baa8b215" title="About Us" {...props} />
  );
}

// id: "802aa12e-08a7-4a82-83f2-af76a6fae064"
// title: ""
// type: :reference
// key: "footer"
// parent_id: "c12dfeb6-358c-459c-9b5e-f875ac5f38a4"
export function Footer802aa12e08a74a8283f2Af76a6fae064(props: any) {
  return (
    <DefaultFooterF5ee257f7f8b488eA8162e51e9c705dc parentTag="Footer802aa12e08a74a8283f2Af76a6fae064" {...props} />
  );
}

// id: "4a3b0e21-b95c-46fd-b386-3fa6bc19c1cc"
// title: ""
// type: :reference
// key: "footer"
// parent_id: "88e97e86-403c-48d6-9113-54ad91970a52"
export function Footer4a3b0e21B95c46fdB3863fa6bc19c1cc(props: any) {
  return (
    <DefaultFooterF5ee257f7f8b488eA8162e51e9c705dc parentTag="Footer4a3b0e21B95c46fdB3863fa6bc19c1cc" {...props} />
  );
}

// id: "cf26f1f2-01fc-4fe2-9d69-20feb45a6c02"
// title: "Login Header"
// type: :reference
// key: "header"
// parent_id: "31818322-fff0-4ed6-8518-eeef698bf98d"
export function HeaderCf26f1f201fc4fe29d6920feb45a6c02(props: any) {
  return (
    <SecondaryMarketingHeaderA2013df6Cc29458b8bd86b53c30a642b parentTag="HeaderCf26f1f201fc4fe29d6920feb45a6c02" title="Login" {...props} />
  );
}

// id: "4586270f-41a0-45d6-947c-5aa76474c8ea"
// title: ""
// type: :reference
// key: "header"
// parent_id: "37564e7d-2e5c-4026-b341-786c49947cac"
export function Header4586270f41a045d6947c5aa76474c8ea(props: any) {
  return (
    <SecondaryMarketingHeaderA2013df6Cc29458b8bd86b53c30a642b parentTag="Header4586270f41a045d6947c5aa76474c8ea" title="Resources" {...props} />
  );
}

// id: "bc40802b-5eeb-4ee1-8533-92cafe603ff9"
// title: ""
// type: :reference
// key: "header"
// parent_id: "fb812ed6-61eb-47f0-9fa1-50fcc87dc320"
export function HeaderBc40802b5eeb4ee1853392cafe603ff9(props: any) {
  return (
    <MembersAreaHeaderE8fa3b709fbc41628009Be89e3c2d4bc parentTag="HeaderBc40802b5eeb4ee1853392cafe603ff9" {...props} />
  );
}

// id: "f0e0c634-d680-4659-9319-ca993b635e4f"
// title: ""
// type: :text
// key: "navMenuSlug"
// parent_id: "0730e74e-76e4-483c-b0e4-50a8efd88835"
export const NavMenuSlugF0e0c634D68046599319Ca993b635e4f = "marketing-primary-nav";

// id: "0498b142-ef1b-4864-839a-279b6088ba96"
// title: "About Page PostSlug"
// type: :text
// key: "postSlug"
// parent_id: "5272ff64-3ed7-4c77-a39c-774f1341a73e"
export const PostSlug0498b142Ef1b4864839a279b6088ba96 = "about-page-posts";

// id: "23e320e9-cc48-44ca-b474-921bed987350"
// title: ""
// type: :text
// key: "class"
// parent_id: "0730e74e-76e4-483c-b0e4-50a8efd88835"
export const Class23e320e9Cc4844caB474921bed987350 = "navigation";

// id: "c12dfeb6-358c-459c-9b5e-f875ac5f38a4"
// title: "Member Pages"
// type: :html
// key: "member-pages"
// parent_id: nil
export function MemberPagesC12dfeb6358c459c9b5eF875ac5f38a4(props: any) {
  const SecondaryNavigation = AppStore.presets["SecondaryNavigation"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} MemberPagesC12dfeb6358c459c9b5eF875ac5f38a4`}>
      {props["header"] || <MembersAreaHeaderE8fa3b709fbc41628009Be89e3c2d4bc parentTag="HeaderB1d1589e0f314e479345331d4b130d5b" {...props} />}
      {props["secondary-nav"] || <SecondaryNavigation parentTag={buildClassName("SecondaryNav94b293ccB5a446a8A146B44c8ca28797", parentTag)} {...props} />}
      {props.children}
      {props["footer"] || <DefaultFooterF5ee257f7f8b488eA8162e51e9c705dc parentTag="Footer802aa12e08a74a8283f2Af76a6fae064" {...props} />}
    </div>
  );
}

// id: "ef6a4657-43ea-4a4b-825e-82bb3e33a2fb"
// title: "Home Page"
// type: :html
// key: "home-page"
// parent_id: nil
export function HomePageEf6a465743ea4a4b825e82bb3e33a2fb(props: any) {
  const WelcomeBackPopUp = AppStore.presets["WelcomeBackPopUp"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} HomePageEf6a465743ea4a4b825e82bb3e33a2fb page`}>
      {props["header"] || <HomeHeaderDa36af7f51c0441aBff2Bc70ced6e2be parentTag="HeaderCa2be9abD94047dfB254B6615a7817e5" {...props} />}
      {props["welcome-back"] || <WelcomeBackPopUp parentTag={buildClassName("WelcomeBack340833955dc344978fe694253097dfc4", parentTag)} {...props} />}
      {props["body"] || <MarketingBody4072b52554af4750A2a535d5874f3ac4 parentTag="BodyD63494f7443f497380cc50dac3774575" postSlug="home-page-posts2" {...props} />}
      {props["quote"] || <Quote8e157ea0B38d494e82e7Afc17aa7c281 />}
      {props["footer"] || <DefaultFooterF5ee257f7f8b488eA8162e51e9c705dc parentTag="Footer3a58d758282d46409786E576fd1b2efc" {...props} />}
    </div>
  );
}

// id: "05f48960-be1e-40dd-b100-d3ba346c1cf4"
// title: ""
// type: :reference
// key: "footer"
// parent_id: "f87d1996-9fdf-45b2-9ac7-f27efa16addf"
export function Footer05f48960Be1e40ddB100D3ba346c1cf4(props: any) {
  return (
    <DefaultFooterF5ee257f7f8b488eA8162e51e9c705dc parentTag="Footer05f48960Be1e40ddB100D3ba346c1cf4" {...props} />
  );
}

// id: "b8f8cae7-6675-4d75-9256-93532adfaddc"
// title: "Marketing Cards"
// type: :gf_preset
// key: "marketing-cards"
// parent_id: "4072b525-54af-4750-a2a5-35d5874f3ac4"
export function MarketingCardsB8f8cae766754d75925693532adfaddc(props: any) {
  const MarketingCards = AppStore.presets["MarketingCards"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <MarketingCards parentTag={buildClassName("MarketingCardsB8f8cae766754d75925693532adfaddc", parentTag)} {...props} />
  );
}

// id: "8e157ea0-b38d-494e-82e7-afc17aa7c281"
// title: ""
// type: :html
// key: "quote"
// parent_id: "ef6a4657-43ea-4a4b-825e-82bb3e33a2fb"
export function Quote8e157ea0B38d494e82e7Afc17aa7c281(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} Quote8e157ea0B38d494e82e7Afc17aa7c281 PageQuote`}>
      <img src="https://mp1md-pub.s3-us-west-2.amazonaws.com/content/fDIDlI3qk75Cn3iS_regular.jpeg" className="image opacity" />
      <div className="TextContent">
        Our mission is to change the world by promoting healthy intimacy through community and education.
      </div>
    </div>
  );
}

// id: "5272ff64-3ed7-4c77-a39c-774f1341a73e"
// title: ""
// type: :reference
// key: "body"
// parent_id: "c6154e6c-4c0d-4773-a746-d098c17e3649"
export function Body5272ff643ed74c77A39c774f1341a73e(props: any) {
  return (
    <MarketingBody4072b52554af4750A2a535d5874f3ac4 parentTag="Body5272ff643ed74c77A39c774f1341a73e" postSlug="about-page-posts" {...props} />
  );
}

// id: "55960ce8-e94b-4336-a68d-9d1dbe3983d1"
// title: ""
// type: :gf_preset
// key: "button"
// parent_id: "abf9e1af-f056-432e-b235-d5cbbeb3005a"
export function Button55960ce8E94b4336A68d9d1dbe3983d1(props: any) {
  const Button = AppStore.presets["Button"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <Button parentTag={buildClassName("Button55960ce8E94b4336A68d9d1dbe3983d1", parentTag)} href="/join" label="Join Us" {...props} />
  );
}

// id: "d60e68a0-d5e1-4605-b044-1d6a45f30fe8"
// title: ""
// type: :text
// key: "href"
// parent_id: "55960ce8-e94b-4336-a68d-9d1dbe3983d1"
export const HrefD60e68a0D5e14605B0441d6a45f30fe8 = "/join";

// id: "e297285a-3b00-464d-99ca-ea808ed2a338"
// title: ""
// type: :text
// key: "title"
// parent_id: "64b483ef-edb3-46db-b533-3d7dfe279129"
export const TitleE297285a3b00464d99caEa808ed2a338 = "Join Us";

// id: "db249b29-b62c-497b-9572-8e1f31975bb0"
// title: ""
// type: :text
// key: "title"
// parent_id: "4586270f-41a0-45d6-947c-5aa76474c8ea"
export const TitleDb249b29B62c497b95728e1f31975bb0 = "Resources";

// id: "29ecdccf-62bc-4536-98c8-423583732f35"
// title: "Members Header"
// type: :reference
// key: "header"
// parent_id: nil
export function Header29ecdccf62bc453698c8423583732f35(props: any) {
  return (
    <MembersAreaHeaderE8fa3b709fbc41628009Be89e3c2d4bc parentTag="Header29ecdccf62bc453698c8423583732f35" {...props} />
  );
}

// id: "31818322-fff0-4ed6-8518-eeef698bf98d"
// title: "Login Page"
// type: :html
// key: "login-page"
// parent_id: nil
export function LoginPage31818322Fff04ed68518Eeef698bf98d(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <>
      {props["header"] || <SecondaryMarketingHeaderA2013df6Cc29458b8bd86b53c30a642b parentTag="HeaderCf26f1f201fc4fe29d6920feb45a6c02" title="Login" {...props} />}
      {props.children}
      {props["footer"] || <DefaultFooterF5ee257f7f8b488eA8162e51e9c705dc parentTag="Footer0e3d36ba5fe14c518925365933845aef" {...props} />}
    </>
  );
}

// id: "994528f6-71ce-4a97-9733-a4970d9d4bf3"
// title: "Secondary Marketing Header Reference"
// type: :reference
// key: "header"
// parent_id: nil
export function Header994528f671ce4a979733A4970d9d4bf3(props: any) {
  return (
    <SecondaryMarketingHeaderA2013df6Cc29458b8bd86b53c30a642b parentTag="Header994528f671ce4a979733A4970d9d4bf3" {...props} />
  );
}

// id: "51a5a355-57fd-406d-ba19-076d6811c3a8"
// title: ""
// type: :gf_preset
// key: "groupflow"
// parent_id: "f5ee257f-7f8b-488e-a816-2e51e9c705dc"
export function Groupflow51a5a35557fd406dBa19076d6811c3a8(props: any) {
  const GroupFlowTag = AppStore.presets["GroupFlowTag"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <GroupFlowTag parentTag={buildClassName("Groupflow51a5a35557fd406dBa19076d6811c3a8", parentTag)} {...props} />
  );
}

// id: "031bfc10-7975-485d-b378-231c97aefdc2"
// title: ""
// type: :reference
// key: "header-top"
// parent_id: "a2013df6-cc29-458b-8bd8-6b53c30a642b"
export function HeaderTop031bfc107975485dB378231c97aefdc2(props: any) {
  return (
    <DefaultHeaderTopFf89aa2e02c24219Bd0261dc713fa33b parentTag="HeaderTop031bfc107975485dB378231c97aefdc2" {...props} />
  );
}

// id: "cb2104c4-d954-4c57-ae25-6a34f9012b9b"
// title: ""
// type: :reference
// key: "header"
// parent_id: "6e94536b-4585-4a65-b275-4b20df9e3629"
export function HeaderCb2104c4D9544c57Ae256a34f9012b9b(props: any) {
  return (
    <SecondaryMarketingHeaderA2013df6Cc29458b8bd86b53c30a642b parentTag="HeaderCb2104c4D9544c57Ae256a34f9012b9b" title="Join Us" {...props} />
  );
}

// id: "f48a0877-92e5-45cc-8c3a-4053349e72db"
// title: "Public Primary Navigation"
// type: :text
// key: "navMenuSlug"
// parent_id: "ffe2dd91-e56b-449c-b268-ca249c9cc18e"
export const NavMenuSlugF48a087792e545cc8c3a4053349e72db = "marketing-primary-nav";

// id: "3f051cb3-fa14-4d8d-a6e6-7f043f15927a"
// title: ""
// type: :text
// key: "title"
// parent_id: "ffe2dd91-e56b-449c-b268-ca249c9cc18e"
export const Title3f051cb3Fa144d8dA6e67f043f15927a = null;

// id: "2274b08d-16c8-4bdb-bd8e-42640dc5957b"
// title: ""
// type: :text
// key: "title"
// parent_id: "a2013df6-cc29-458b-8bd8-6b53c30a642b"
export const Title2274b08d16c84bdbBd8e42640dc5957b = "Secondary Marketing Title 1";

// id: "aef61128-039c-4f80-883c-4066f638ebae"
// title: ""
// type: :text
// key: "title"
// parent_id: "235c7998-c312-49d7-bd33-4c04e3251110"
export const TitleAef61128039c4f80883c4066f638ebae = "Secondary Marketing Title 2";

// id: "c016557c-a827-4821-b992-c40d21d7decf"
// title: ""
// type: :text
// key: "label"
// parent_id: "55960ce8-e94b-4336-a68d-9d1dbe3983d1"
export const LabelC016557cA8274821B992C40d21d7decf = "Join Us";

// id: "7708eb80-1823-40ce-b02a-1e63c17e3769"
// title: "Login Page Title"
// type: :text
// key: "title"
// parent_id: "cf26f1f2-01fc-4fe2-9d69-20feb45a6c02"
export const Title7708eb80182340ceB02a1e63c17e3769 = "Login";

// id: "ffe2dd91-e56b-449c-b268-ca249c9cc18e"
// title: "Default Header Reference"
// type: :reference
// key: "default-header"
// parent_id: nil
export function DefaultHeaderFfe2dd91E56b449cB268Ca249c9cc18e(props: any) {
  return (
    <ShortHeader00e74f76B0314fbb852b23e9a5ca18eb parentTag="DefaultHeaderFfe2dd91E56b449cB268Ca249c9cc18e" navMenuSlug="marketing-primary-nav" title {...props} />
  );
}

// id: "7bdcdb8a-8b37-45ad-9719-ffff926f91d0"
// title: "Secondary Marketing Hero"
// type: :html
// key: "secondary-marketing-hero"
// parent_id: nil
export function SecondaryMarketingHero7bdcdb8a8b3745ad9719Ffff926f91d0(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} SecondaryMarketingHero7bdcdb8a8b3745ad9719Ffff926f91d0 hero`}>
      <div className="title">
        {props["title"] || Title598a0b71C73a42f78a7f7af545a60887}
      </div>
    </div>
  );
}

// id: "d5517b1c-2ea1-4778-9734-046b6cf794de"
// title: "Admin Pages"
// type: :html
// key: "admin-pages"
// parent_id: nil
export function AdminPagesD5517b1c2ea147789734046b6cf794de(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} AdminPagesD5517b1c2ea147789734046b6cf794de`}>
      {props.children}
    </div>
  );
}

// id: "0e3d36ba-5fe1-4c51-8925-365933845aef"
// title: "Login Footer"
// type: :reference
// key: "footer"
// parent_id: "31818322-fff0-4ed6-8518-eeef698bf98d"
export function Footer0e3d36ba5fe14c518925365933845aef(props: any) {
  return (
    <DefaultFooterF5ee257f7f8b488eA8162e51e9c705dc parentTag="Footer0e3d36ba5fe14c518925365933845aef" {...props} />
  );
}

// id: "94b293cc-b5a4-46a8-a146-b44c8ca28797"
// title: ""
// type: :gf_preset
// key: "secondary-nav"
// parent_id: "c12dfeb6-358c-459c-9b5e-f875ac5f38a4"
export function SecondaryNav94b293ccB5a446a8A146B44c8ca28797(props: any) {
  const SecondaryNavigation = AppStore.presets["SecondaryNavigation"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <SecondaryNavigation parentTag={buildClassName("SecondaryNav94b293ccB5a446a8A146B44c8ca28797", parentTag)} {...props} />
  );
}

// id: "598a0b71-c73a-42f7-8a7f-7af545a60887"
// title: ""
// type: :text
// key: "title"
// parent_id: "7bdcdb8a-8b37-45ad-9719-ffff926f91d0"
export const Title598a0b71C73a42f78a7f7af545a60887 = "Default Title";

// id: "3a58d758-282d-4640-9786-e576fd1b2efc"
// title: ""
// type: :reference
// key: "footer"
// parent_id: "ef6a4657-43ea-4a4b-825e-82bb3e33a2fb"
export function Footer3a58d758282d46409786E576fd1b2efc(props: any) {
  return (
    <DefaultFooterF5ee257f7f8b488eA8162e51e9c705dc parentTag="Footer3a58d758282d46409786E576fd1b2efc" {...props} />
  );
}

// id: "2ac18fda-d436-4d9e-9cd1-548dfafe51df"
// title: ""
// type: :gf_preset
// key: "navigation"
// parent_id: "00e74f76-b031-4fbb-852b-23e9a5ca18eb"
export function Navigation2ac18fdaD4364d9e9cd1548dfafe51df(props: any) {
  const PrimaryNavigation = AppStore.presets["PrimaryNavigation"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <PrimaryNavigation parentTag={buildClassName("Navigation2ac18fdaD4364d9e9cd1548dfafe51df", parentTag)} navMenuSlug="members-primary-nav" {...props} />
  );
}

// id: "a2e3538b-9064-476a-a962-6f71ca2064fe"
// title: ""
// type: :gf_preset
// key: "copyright"
// parent_id: "f5ee257f-7f8b-488e-a816-2e51e9c705dc"
export function CopyrightA2e3538b9064476aA9626f71ca2064fe(props: any) {
  const GroupCopyright = AppStore.presets["GroupCopyright"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <GroupCopyright parentTag={buildClassName("CopyrightA2e3538b9064476aA9626f71ca2064fe", parentTag)} {...props} />
  );
}

// id: "6fee87a6-ea7d-4d53-96f6-4be5b3cb3cf1"
// title: ""
// type: :text
// key: "navMenuSlug"
// parent_id: "1e2af36e-a86e-4a5c-a048-0a554072cb1d"
export const NavMenuSlug6fee87a6Ea7d4d5396f64be5b3cb3cf1 = "members-primary-nav";

// id: "38437151-cfff-4aad-85dd-508d43e6fd55"
// title: ""
// type: :text
// key: "navMenuSlug"
// parent_id: "43e1101e-01c7-4852-a280-42c347142d49"
export const NavMenuSlug38437151Cfff4aad85dd508d43e6fd55 = "members-primary-nav";

// id: "cedb3851-d303-4fa9-b29d-1267b5ad7c0a"
// title: ""
// type: :reference
// key: "label"
// parent_id: "8eecb1e6-a1d4-49cf-8787-c7c541936ae6"
export function LabelCedb3851D3034fa9B29d1267b5ad7c0a(props: any) {
  return (
    <DefaultLogo898dba46C1754a6991f823b95a39a06f parentTag="LabelCedb3851D3034fa9B29d1267b5ad7c0a" {...props} />
  );
}

// id: "abf9e1af-f056-432e-b235-d5cbbeb3005a"
// title: "Home Page Hero"
// type: :html
// key: "home-page-hero"
// parent_id: nil
export function HomePageHeroAbf9e1afF056432eB235D5cbbeb3005a(props: any) {
  const Button = AppStore.presets["Button"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} HomePageHeroAbf9e1afF056432eB235D5cbbeb3005a hero`}>
      <div className="title">
        Learn. Play. Grow. Run.
      </div>
      <div className="body">
        Our mission is to change the world by promoting healthy sexuality through community and education.
      </div>
      <div className="button">
        {props["button"] || <Button parentTag={buildClassName("Button55960ce8E94b4336A68d9d1dbe3983d1", parentTag)} href="/join" label="Join Us" {...props} />}
      </div>
    </div>
  );
}

// id: "aae75d21-fdb0-46a4-8ba8-330ee3921424"
// title: "Public Events Page"
// type: :html
// key: "public-events-page"
// parent_id: nil
export function PublicEventsPageAae75d21Fdb046a48ba8330ee3921424(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} PublicEventsPageAae75d21Fdb046a48ba8330ee3921424`}>
      {props["header"] || <SecondaryMarketingHeaderA2013df6Cc29458b8bd86b53c30a642b parentTag="Header84fb6d786c02485dBd8b6a586b669b41" title="Public Events" {...props} />}
      {props.children}
      {props["footer"] || <DefaultFooterF5ee257f7f8b488eA8162e51e9c705dc parentTag="Footer14bb2a1aAa5d4ce2A56182763978cda8" {...props} />}
    </div>
  );
}

// id: "898dba46-c175-4a69-91f8-23b95a39a06f"
// title: "Logo"
// type: :html
// key: "default-logo"
// parent_id: nil
export function DefaultLogo898dba46C1754a6991f823b95a39a06f(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <img src="https://mp1md-pub.s3.amazonaws.com/orgs/demo-2/logo-wide-vector.svg" className={`${parentTag || ""} DefaultLogo898dba46C1754a6991f823b95a39a06f logo`} alt="logo" />
  );
}

// id: "e55d640d-4927-491e-a501-94d649a2a421"
// title: ""
// type: :text
// key: "backgroundImage"
// parent_id: "00e74f76-b031-4fbb-852b-23e9a5ca18eb"
export const BackgroundImageE55d640d4927491eA50194d649a2a421 = "https://mp1md-pub.s3.amazonaws.com/spla/images/spla-skyline.jpeg";

// id: "14bb2a1a-aa5d-4ce2-a561-82763978cda8"
// title: ""
// type: :reference
// key: "footer"
// parent_id: "aae75d21-fdb0-46a4-8ba8-330ee3921424"
export function Footer14bb2a1aAa5d4ce2A56182763978cda8(props: any) {
  return (
    <DefaultFooterF5ee257f7f8b488eA8162e51e9c705dc parentTag="Footer14bb2a1aAa5d4ce2A56182763978cda8" {...props} />
  );
}

// id: "43e1101e-01c7-4852-a280-42c347142d49"
// title: ""
// type: :reference
// key: "header-top"
// parent_id: "e8fa3b70-9fbc-4162-8009-be89e3c2d4bc"
export function HeaderTop43e1101e01c74852A28042c347142d49(props: any) {
  return (
    <DefaultHeaderTopFf89aa2e02c24219Bd0261dc713fa33b parentTag="HeaderTop43e1101e01c74852A28042c347142d49" navMenuSlug="members-primary-nav" {...props} />
  );
}

// id: "08d9a5fb-0988-4805-88d4-04eb2141dcd7"
// title: ""
// type: :reference
// key: "header"
// parent_id: "88e97e86-403c-48d6-9113-54ad91970a52"
export function Header08d9a5fb0988480588d404eb2141dcd7(props: any) {
  return (
    <SecondaryMarketingHeaderA2013df6Cc29458b8bd86b53c30a642b parentTag="Header08d9a5fb0988480588d404eb2141dcd7" title="Secondary Page" {...props} />
  );
}

// id: "34083395-5dc3-4497-8fe6-94253097dfc4"
// title: ""
// type: :gf_preset
// key: "welcome-back"
// parent_id: "ef6a4657-43ea-4a4b-825e-82bb3e33a2fb"
export function WelcomeBack340833955dc344978fe694253097dfc4(props: any) {
  const WelcomeBackPopUp = AppStore.presets["WelcomeBackPopUp"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <WelcomeBackPopUp parentTag={buildClassName("WelcomeBack340833955dc344978fe694253097dfc4", parentTag)} {...props} />
  );
}

// id: "829ce529-adf1-4030-a640-b90623240c03"
// title: ""
// type: :text
// key: "navMenuSlug"
// parent_id: "d7610a2c-a750-465c-8f5f-e9bdbe2f2fd2"
export const NavMenuSlug829ce529Adf14030A640B90623240c03 = "footer-nav";

// id: "edf878f2-3289-4852-ae05-eae3d0735139"
// title: ""
// type: :text
// key: "title"
// parent_id: "e5af14be-213d-498b-8c2d-3314baa8b215"
export const TitleEdf878f232894852Ae05Eae3d0735139 = "About Us";

// id: "addb871c-f9f8-44c1-91ad-c366913e8c9c"
// title: "Home Page postSlug"
// type: :text
// key: "postSlug"
// parent_id: "d63494f7-443f-4973-80cc-50dac3774575"
export const PostSlugAddb871cF9f844c191adC366913e8c9c = "home-page-posts2";

// id: "88e97e86-403c-48d6-9113-54ad91970a52"
// title: "Secondary Page"
// type: :html
// key: "secondary-page"
// parent_id: nil
export function SecondaryPage88e97e86403c48d6911354ad91970a52(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} SecondaryPage88e97e86403c48d6911354ad91970a52`}>
      {props["header"] || <SecondaryMarketingHeaderA2013df6Cc29458b8bd86b53c30a642b parentTag="Header08d9a5fb0988480588d404eb2141dcd7" title="Secondary Page" {...props} />}
      <div className="text-content">
        {props.children}
      </div>
      {props["footer"] || <DefaultFooterF5ee257f7f8b488eA8162e51e9c705dc parentTag="Footer4a3b0e21B95c46fdB3863fa6bc19c1cc" {...props} />}
    </div>
  );
}

// id: "da36af7f-51c0-441a-bff2-bc70ced6e2be"
// title: "Home Header"
// type: :html
// key: "home-header"
// parent_id: nil
export function HomeHeaderDa36af7f51c0441aBff2Bc70ced6e2be(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} HomeHeaderDa36af7f51c0441aBff2Bc70ced6e2be site-header`}>
      <div className="background-color" />
      <div className="background-image" />
      <div className="header-top-wrapper">
        <div className="header-top-opacity">
        </div>
        {props["header-top"] || <DefaultHeaderTopFf89aa2e02c24219Bd0261dc713fa33b parentTag="HeaderTopAa589c05590c4015B146235f6d25a527" {...props} />}
      </div>
      {props["hero"] || <HomePageHeroAbf9e1afF056432eB235D5cbbeb3005a parentTag="HeroAbfbba9cF3b841019d34A82e34abbd2f" {...props} />}
    </div>
  );
}

// id: "abfbba9c-f3b8-4101-9d34-a82e34abbd2f"
// title: ""
// type: :reference
// key: "hero"
// parent_id: "da36af7f-51c0-441a-bff2-bc70ced6e2be"
export function HeroAbfbba9cF3b841019d34A82e34abbd2f(props: any) {
  return (
    <HomePageHeroAbf9e1afF056432eB235D5cbbeb3005a parentTag="HeroAbfbba9cF3b841019d34A82e34abbd2f" {...props} />
  );
}

// id: "eacc0c03-af98-4eb3-84d8-1a29a90bd9f2"
// title: ""
// type: :html
// key: "social-links"
// parent_id: "f5ee257f-7f8b-488e-a816-2e51e9c705dc"
export function SocialLinksEacc0c03Af984eb384d81a29a90bd9f2(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} SocialLinksEacc0c03Af984eb384d81a29a90bd9f2 flex flex-row justify-center gap-6 flex flex-row justify-center gap-6`}>
      <a className="hover:brightness-110" href="https://www.instagram.com/sexposla/">
        <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg" title="Instagram icon" className="fill-current" style={{width: "30px", height: "30px"}}>
          <path d="M18.5769 0C8.34616 0 0 8.34616 0 18.5769V45.4423C0 55.6538 8.34616 64 18.5769 64H45.4423C55.6538 64 64 55.6539 64 45.4231V18.5769C64 8.34616 55.6539 0 45.4231 0H18.5769ZM18.5769 4.92308H45.4231C53 4.92308 59.0769 11 59.0769 18.5769V45.4231C59.0769 53 53 59.0769 45.4423 59.0769H18.5769C11 59.0769 4.92308 53 4.92308 45.4423V18.5769C4.92308 11 11 4.92308 18.5769 4.92308ZM51.6923 9.84615C50.3269 9.84615 49.2308 10.9423 49.2308 12.3077C49.2308 13.6731 50.3269 14.7692 51.6923 14.7692C53.0577 14.7692 54.1538 13.6731 54.1538 12.3077C54.1538 10.9423 53.0577 9.84615 51.6923 9.84615ZM32 14.7692C22.5192 14.7692 14.7692 22.5192 14.7692 32C14.7692 41.4808 22.5192 49.2308 32 49.2308C41.4808 49.2308 49.2308 41.4808 49.2308 32C49.2308 22.5192 41.4808 14.7692 32 14.7692ZM32 19.6923C38.8269 19.6923 44.3077 25.1731 44.3077 32C44.3077 38.8269 38.8269 44.3077 32 44.3077C25.1731 44.3077 19.6923 38.8269 19.6923 32C19.6923 25.1731 25.1731 19.6923 32 19.6923Z" />
        </svg>
      </a>
      <a className="hover:brightness-110" href="https://www.facebook.com/groups/1104997742859454">
        <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg" title="Facebook icon" className="fill-current" style={{width: "30px", height: "30px"}}>
          <path d="M32 0C14.356 0 0 14.356 0 32C0 49.644 14.356 64 32 64C49.644 64 64 49.644 64 32C64 14.356 49.644 0 32 0ZM32 4.92308C46.9833 4.92308 59.0769 17.0167 59.0769 32C59.0769 45.6189 49.0711 56.8173 36 58.75V39.8269H43.6442L44.8462 32.0673H36V27.8173C36 24.5927 37.0495 21.7308 40.0673 21.7308H44.9135V14.9519C44.0618 14.8387 42.2625 14.5865 38.8558 14.5865C31.7468 14.5865 27.5769 18.3428 27.5769 26.8942V32.0577H20.2692V39.8269H27.5865V58.6827C14.719 56.5766 4.92308 45.4748 4.92308 32C4.92308 17.0167 17.0167 4.92308 32 4.92308Z" />
        </svg>
      </a>
      <a className="hover:brightness-110" href="https://twitter.com/sexposla">
        <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg" title="Twitter icon" className="fill-current" style={{width: "30px", height: "30px"}}>
          <path d="M8 0C3.61317 0 0 3.61317 0 8V56C0 60.3868 3.61317 64 8 64H56C60.3868 64 64 60.3868 64 56V8C64 3.61317 60.3868 0 56 0H8ZM8 5.33333H56C57.5012 5.33333 58.6667 6.49883 58.6667 8V56C58.6667 57.5012 57.5012 58.6667 56 58.6667H8C6.49883 58.6667 5.33333 57.5012 5.33333 56V8C5.33333 6.49883 6.49883 5.33333 8 5.33333ZM41.25 16C36.754 16 33.0988 19.6552 33.1042 24.1458C33.1042 24.9832 33.3958 25.5442 33.3958 26.1042C26.6545 25.8215 21.0557 22.4608 17.125 17.6875C16.2823 18.8128 16 20.2119 16 21.6146C16 24.4199 17.0931 26.6674 19.3438 28.6354C17.8504 28.4328 17.1253 28.0704 16 27.5104C16 31.4411 18.5029 34.527 22.1563 35.375C22.1563 35.375 20.7489 35.6563 19.9063 35.6563C19.2663 35.6563 18.5 35.375 18.5 35.375C19.6253 38.463 22.43 40.9896 26.0833 40.9896C23.278 42.9576 19.6221 44.3542 15.9688 44.3542H14C16.6667 46.6688 20.6635 48 26.3542 48C41.2395 48 49.3958 35.6563 49.3958 24.9896V23.8646C50.8038 22.7393 52.2027 21.3365 53.3333 19.6458C51.648 20.4938 50.2428 20.7694 48.5521 21.0521C50.2374 19.9321 51.6483 18.5252 52.2083 16.5625C50.8057 17.3998 49.1243 18.2382 47.1563 18.5208C45.7483 16.8408 43.5007 16 41.25 16Z" />
        </svg>
      </a>
      <a className="hover:brightness-110" href="https://www.sexpositivelosangeles.org/resources/discord">
        <svg width="64" height="71" viewBox="0 0 64 71" fill="none" xmlns="http://www.w3.org/2000/svg" title="Discord icon" className="fill-current" style={{width: "30px", height: "30px"}}>
          <path d="M7.11111 0C3.22467 0 0 3.19494 0 7.04555V56.3644C0 60.2127 3.22079 63.4062 7.10417 63.4099L48.6875 63.5063C49.2395 63.5064 49.784 63.3792 50.2778 63.1347L58.2014 69.506C58.7228 69.9259 59.3537 70.1908 60.0208 70.27C60.688 70.3493 61.3641 70.2396 61.971 69.9538C62.5778 69.6679 63.0905 69.2175 63.4496 68.6549C63.8087 68.0922 63.9995 67.4402 64 66.7745V7.04555C64 3.19494 60.7753 0 56.8889 0H7.11111ZM7.11111 7.04555H56.8889V59.3711L50.1667 53.97C49.6617 53.5648 49.0543 53.3044 48.4104 53.2172C47.7666 53.1299 47.1109 53.2191 46.5146 53.4752C45.9184 53.7312 45.4043 54.1442 45.0283 54.6693C44.6523 55.1945 44.4288 55.8117 44.3819 56.4538L7.11806 56.3644C7.11574 56.3644 7.11343 56.3644 7.11111 56.3644V7.04555ZM27.1528 18.1643C22.559 18.6011 19.0694 21.3224 19.0694 21.3224C19.0694 21.3224 14.9333 27.2306 14.2222 38.7712C18.4036 43.5058 24.7292 43.5324 24.7292 43.5324L26.0417 41.7985C24.7012 41.3406 23.5587 40.7531 22.3889 39.8583L22.6319 39.1427C24.8186 40.1326 27.6267 40.7114 32 40.7114C36.3733 40.7114 39.1814 40.1291 41.3681 39.1427L41.6111 39.8583C40.4378 40.7566 39.2952 41.3441 37.9583 41.7985L39.2708 43.5324C39.2708 43.5324 45.5964 43.5058 49.7778 38.7712C49.0667 27.2306 44.9306 21.3224 44.9306 21.3224C44.9306 21.3224 41.2277 18.4884 36.8472 18.1643L35.9444 19.9945C34.604 19.7514 33.2053 19.5748 32 19.5748C30.7556 19.5748 29.3572 19.7415 28.0417 19.967L27.1528 18.1643ZM25.5972 28.1822C27.1617 28.1822 28.4444 29.7604 28.4444 31.705C28.4444 33.6495 27.1617 35.2277 25.5972 35.2277C24.0328 35.2277 22.7569 33.6495 22.7569 31.705C22.7569 29.7604 24.0328 28.1822 25.5972 28.1822ZM38.4028 28.1822C39.9672 28.1822 41.2431 29.7604 41.2431 31.705C41.2431 33.6495 39.9672 35.2277 38.4028 35.2277C36.8383 35.2277 35.5556 33.6495 35.5556 31.705C35.5556 29.7604 36.8383 28.1822 38.4028 28.1822Z" />
        </svg>
      </a>
    </div>
  );
}

// id: "0730e74e-76e4-483c-b0e4-50a8efd88835"
// title: ""
// type: :gf_preset
// key: "navigation"
// parent_id: "ff89aa2e-02c2-4219-bd02-61dc713fa33b"
export function Navigation0730e74e76e4483cB0e450a8efd88835(props: any) {
  const PrimaryNavigation = AppStore.presets["PrimaryNavigation"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <PrimaryNavigation parentTag={buildClassName("Navigation0730e74e76e4483cB0e450a8efd88835", parentTag)} navMenuSlug="marketing-primary-nav" className="navigation" {...props} />
  );
}

// id: "d7610a2c-a750-465c-8f5f-e9bdbe2f2fd2"
// title: ""
// type: :gf_preset
// key: "links"
// parent_id: "f5ee257f-7f8b-488e-a816-2e51e9c705dc"
export function LinksD7610a2cA750465c8f5fE9bdbe2f2fd2(props: any) {
  const Navigation = AppStore.presets["Navigation"];
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <Navigation parentTag={buildClassName("LinksD7610a2cA750465c8f5fE9bdbe2f2fd2", parentTag)} navMenuSlug="footer-nav" {...props} />
  );
}

// id: "604036b3-9995-48b2-8046-20bfc75b383f"
// title: ""
// type: :html
// key: "social-links"
// parent_id: "f5ee257f-7f8b-488e-a816-2e51e9c705dc"
export function SocialLinks604036b3999548b2804620bfc75b383f(props: any) {
  const { parentTag, ...rest } = props;
  props = rest;
  return (
    <div className={`${parentTag || ""} SocialLinks604036b3999548b2804620bfc75b383f flex flex-row justify-center gap-6 flex flex-row justify-center gap-6`}>
      <a className="hover:brightness-110" href="https://www.instagram.com/sexposla/">
        <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg" title="Instagram icon" className="fill-current" style={{width: "30px", height: "30px"}}>
          <path d="M18.5769 0C8.34616 0 0 8.34616 0 18.5769V45.4423C0 55.6538 8.34616 64 18.5769 64H45.4423C55.6538 64 64 55.6539 64 45.4231V18.5769C64 8.34616 55.6539 0 45.4231 0H18.5769ZM18.5769 4.92308H45.4231C53 4.92308 59.0769 11 59.0769 18.5769V45.4231C59.0769 53 53 59.0769 45.4423 59.0769H18.5769C11 59.0769 4.92308 53 4.92308 45.4423V18.5769C4.92308 11 11 4.92308 18.5769 4.92308ZM51.6923 9.84615C50.3269 9.84615 49.2308 10.9423 49.2308 12.3077C49.2308 13.6731 50.3269 14.7692 51.6923 14.7692C53.0577 14.7692 54.1538 13.6731 54.1538 12.3077C54.1538 10.9423 53.0577 9.84615 51.6923 9.84615ZM32 14.7692C22.5192 14.7692 14.7692 22.5192 14.7692 32C14.7692 41.4808 22.5192 49.2308 32 49.2308C41.4808 49.2308 49.2308 41.4808 49.2308 32C49.2308 22.5192 41.4808 14.7692 32 14.7692ZM32 19.6923C38.8269 19.6923 44.3077 25.1731 44.3077 32C44.3077 38.8269 38.8269 44.3077 32 44.3077C25.1731 44.3077 19.6923 38.8269 19.6923 32C19.6923 25.1731 25.1731 19.6923 32 19.6923Z" />
        </svg>
      </a>
      <a className="hover:brightness-110" href="https://www.facebook.com/groups/1104997742859454">
        <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg" title="Facebook icon" className="fill-current" style={{width: "30px", height: "30px"}}>
          <path d="M32 0C14.356 0 0 14.356 0 32C0 49.644 14.356 64 32 64C49.644 64 64 49.644 64 32C64 14.356 49.644 0 32 0ZM32 4.92308C46.9833 4.92308 59.0769 17.0167 59.0769 32C59.0769 45.6189 49.0711 56.8173 36 58.75V39.8269H43.6442L44.8462 32.0673H36V27.8173C36 24.5927 37.0495 21.7308 40.0673 21.7308H44.9135V14.9519C44.0618 14.8387 42.2625 14.5865 38.8558 14.5865C31.7468 14.5865 27.5769 18.3428 27.5769 26.8942V32.0577H20.2692V39.8269H27.5865V58.6827C14.719 56.5766 4.92308 45.4748 4.92308 32C4.92308 17.0167 17.0167 4.92308 32 4.92308Z" />
        </svg>
      </a>
      <a className="hover:brightness-110" href="https://twitter.com/sexposla">
        <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg" title="Twitter icon" className="fill-current" style={{width: "30px", height: "30px"}}>
          <path d="M8 0C3.61317 0 0 3.61317 0 8V56C0 60.3868 3.61317 64 8 64H56C60.3868 64 64 60.3868 64 56V8C64 3.61317 60.3868 0 56 0H8ZM8 5.33333H56C57.5012 5.33333 58.6667 6.49883 58.6667 8V56C58.6667 57.5012 57.5012 58.6667 56 58.6667H8C6.49883 58.6667 5.33333 57.5012 5.33333 56V8C5.33333 6.49883 6.49883 5.33333 8 5.33333ZM41.25 16C36.754 16 33.0988 19.6552 33.1042 24.1458C33.1042 24.9832 33.3958 25.5442 33.3958 26.1042C26.6545 25.8215 21.0557 22.4608 17.125 17.6875C16.2823 18.8128 16 20.2119 16 21.6146C16 24.4199 17.0931 26.6674 19.3438 28.6354C17.8504 28.4328 17.1253 28.0704 16 27.5104C16 31.4411 18.5029 34.527 22.1563 35.375C22.1563 35.375 20.7489 35.6563 19.9063 35.6563C19.2663 35.6563 18.5 35.375 18.5 35.375C19.6253 38.463 22.43 40.9896 26.0833 40.9896C23.278 42.9576 19.6221 44.3542 15.9688 44.3542H14C16.6667 46.6688 20.6635 48 26.3542 48C41.2395 48 49.3958 35.6563 49.3958 24.9896V23.8646C50.8038 22.7393 52.2027 21.3365 53.3333 19.6458C51.648 20.4938 50.2428 20.7694 48.5521 21.0521C50.2374 19.9321 51.6483 18.5252 52.2083 16.5625C50.8057 17.3998 49.1243 18.2382 47.1563 18.5208C45.7483 16.8408 43.5007 16 41.25 16Z" />
        </svg>
      </a>
      <a className="hover:brightness-110" href="https://www.sexpositivelosangeles.org/resources/discord">
        <svg width="64" height="71" viewBox="0 0 64 71" fill="none" xmlns="http://www.w3.org/2000/svg" title="Discord icon" className="fill-current" style={{width: "30px", height: "30px"}}>
          <path d="M7.11111 0C3.22467 0 0 3.19494 0 7.04555V56.3644C0 60.2127 3.22079 63.4062 7.10417 63.4099L48.6875 63.5063C49.2395 63.5064 49.784 63.3792 50.2778 63.1347L58.2014 69.506C58.7228 69.9259 59.3537 70.1908 60.0208 70.27C60.688 70.3493 61.3641 70.2396 61.971 69.9538C62.5778 69.6679 63.0905 69.2175 63.4496 68.6549C63.8087 68.0922 63.9995 67.4402 64 66.7745V7.04555C64 3.19494 60.7753 0 56.8889 0H7.11111ZM7.11111 7.04555H56.8889V59.3711L50.1667 53.97C49.6617 53.5648 49.0543 53.3044 48.4104 53.2172C47.7666 53.1299 47.1109 53.2191 46.5146 53.4752C45.9184 53.7312 45.4043 54.1442 45.0283 54.6693C44.6523 55.1945 44.4288 55.8117 44.3819 56.4538L7.11806 56.3644C7.11574 56.3644 7.11343 56.3644 7.11111 56.3644V7.04555ZM27.1528 18.1643C22.559 18.6011 19.0694 21.3224 19.0694 21.3224C19.0694 21.3224 14.9333 27.2306 14.2222 38.7712C18.4036 43.5058 24.7292 43.5324 24.7292 43.5324L26.0417 41.7985C24.7012 41.3406 23.5587 40.7531 22.3889 39.8583L22.6319 39.1427C24.8186 40.1326 27.6267 40.7114 32 40.7114C36.3733 40.7114 39.1814 40.1291 41.3681 39.1427L41.6111 39.8583C40.4378 40.7566 39.2952 41.3441 37.9583 41.7985L39.2708 43.5324C39.2708 43.5324 45.5964 43.5058 49.7778 38.7712C49.0667 27.2306 44.9306 21.3224 44.9306 21.3224C44.9306 21.3224 41.2277 18.4884 36.8472 18.1643L35.9444 19.9945C34.604 19.7514 33.2053 19.5748 32 19.5748C30.7556 19.5748 29.3572 19.7415 28.0417 19.967L27.1528 18.1643ZM25.5972 28.1822C27.1617 28.1822 28.4444 29.7604 28.4444 31.705C28.4444 33.6495 27.1617 35.2277 25.5972 35.2277C24.0328 35.2277 22.7569 33.6495 22.7569 31.705C22.7569 29.7604 24.0328 28.1822 25.5972 28.1822ZM38.4028 28.1822C39.9672 28.1822 41.2431 29.7604 41.2431 31.705C41.2431 33.6495 39.9672 35.2277 38.4028 35.2277C36.8383 35.2277 35.5556 33.6495 35.5556 31.705C35.5556 29.7604 36.8383 28.1822 38.4028 28.1822Z" />
        </svg>
      </a>
    </div>
  );
}

// id: "b1d1589e-0f31-4e47-9345-331d4b130d5b"
// title: ""
// type: :reference
// key: "header"
// parent_id: "c12dfeb6-358c-459c-9b5e-f875ac5f38a4"
export function HeaderB1d1589e0f314e479345331d4b130d5b(props: any) {
  return (
    <MembersAreaHeaderE8fa3b709fbc41628009Be89e3c2d4bc parentTag="HeaderB1d1589e0f314e479345331d4b130d5b" {...props} />
  );
}

// id: "d63494f7-443f-4973-80cc-50dac3774575"
// title: "Home Page Body"
// type: :reference
// key: "body"
// parent_id: "ef6a4657-43ea-4a4b-825e-82bb3e33a2fb"
export function BodyD63494f7443f497380cc50dac3774575(props: any) {
  return (
    <MarketingBody4072b52554af4750A2a535d5874f3ac4 parentTag="BodyD63494f7443f497380cc50dac3774575" postSlug="home-page-posts2" {...props} />
  );
}

// id: "7eada52f-08c8-46bc-85b3-e3acb9c5f34d"
// title: ""
// type: :text
// key: "navMenuSlug"
// parent_id: "2ac18fda-d436-4d9e-9cd1-548dfafe51df"
export const NavMenuSlug7eada52f08c846bc85b3E3acb9c5f34d = "members-primary-nav";

// id: "d731c245-07ab-4e9b-a3f9-d38b74d5d619"
// title: ""
// type: :text
// key: "title"
// parent_id: "08d9a5fb-0988-4805-88d4-04eb2141dcd7"
export const TitleD731c24507ab4e9bA3f9D38b74d5d619 = "Secondary Page";

const Components = {
  DefaultHeaderTopFf89aa2e02c24219Bd0261dc713fa33b,
  MembersAreaHeaderE8fa3b709fbc41628009Be89e3c2d4bc,
  ResourcesPage37564e7d2e5c4026B341786c49947cac,
  PostSlugB0430b17110644ff89ab3b59298a77dd,
  MarketingBody4072b52554af4750A2a535d5874f3ac4,
  PasswordResetPage9b026caf9791419087501e649c7950c9,
  Label483a0cabF1c5451eB925A26f01f01c2b,
  MessagePagesFb812ed661eb47f09fa150fcc87dc320,
  JoinUsPage00302df12a4445b4986cE7e9d0fe2b45,
  FooterB8a0f2c92fae421c9441C18b0a322004,
  MembersAreaDefaultF87d19969fdf45b29ac7F27efa16addf,
  AboutUsPageC6154e6c4c0d4773A746D098c17e3649,
  SecondaryMarketingHeaderA2013df6Cc29458b8bd86b53c30a642b,
  ButtonClass76e5e31826324b71Ba45Cbc1b69483e6,
  LinkedLogoDd09f2acDe794fa9A598Ce1cb114e5f6,
  Alt7a569048Aa3046e6B58b02fac3bdedcf,
  Header84fb6d786c02485dBd8b6a586b669b41,
  LinkedLogo8eecb1e6A1d449cf8787C7c541936ae6,
  Header1e2af36eA86e4a5cA0480a554072cb1d,
  Logo6d0b44d4041847a7826a91a32870303a,
  Label2f3368d5Be8b4ec5Bf02D3263981acff,
  TitleB361efb694b545e7869d692ae4051c1e,
  MarketingCardsAf6d5bba4c1a400396a5D0403b626ced,
  PageQuote4db482018d694349B85a1212e0e56dc1,
  ShortHeader00e74f76B0314fbb852b23e9a5ca18eb,
  Hero235c7998C31249d7Bd334c04e3251110,
  Header64b483efEdb346dbB5333d7dfe279129,
  HeaderCa2be9abD94047dfB254B6615a7817e5,
  Footer05e4276eF18b4812A8857f50a1096206,
  SocialLinks7bcec08cEdc544a394efFf49914af486,
  HeaderTopAa589c05590c4015B146235f6d25a527,
  Url834df52cBada4829A66479f306cf4885,
  TitleD994d57fA8a747699dde8760c76fd8ba,
  Class8c387e700ead4706A9a3181272fd7321,
  SignupPages6e94536b45854a65B2754b20df9e3629,
  HrefC9bcf3232ed046b790cdC756a5dea53c,
  DefaultFooterF5ee257f7f8b488eA8162e51e9c705dc,
  HeaderE5af14be213d498b8c2d3314baa8b215,
  Footer802aa12e08a74a8283f2Af76a6fae064,
  Footer4a3b0e21B95c46fdB3863fa6bc19c1cc,
  HeaderCf26f1f201fc4fe29d6920feb45a6c02,
  Header4586270f41a045d6947c5aa76474c8ea,
  HeaderBc40802b5eeb4ee1853392cafe603ff9,
  NavMenuSlugF0e0c634D68046599319Ca993b635e4f,
  PostSlug0498b142Ef1b4864839a279b6088ba96,
  Class23e320e9Cc4844caB474921bed987350,
  MemberPagesC12dfeb6358c459c9b5eF875ac5f38a4,
  HomePageEf6a465743ea4a4b825e82bb3e33a2fb,
  Footer05f48960Be1e40ddB100D3ba346c1cf4,
  MarketingCardsB8f8cae766754d75925693532adfaddc,
  Quote8e157ea0B38d494e82e7Afc17aa7c281,
  Body5272ff643ed74c77A39c774f1341a73e,
  Button55960ce8E94b4336A68d9d1dbe3983d1,
  HrefD60e68a0D5e14605B0441d6a45f30fe8,
  TitleE297285a3b00464d99caEa808ed2a338,
  TitleDb249b29B62c497b95728e1f31975bb0,
  Header29ecdccf62bc453698c8423583732f35,
  LoginPage31818322Fff04ed68518Eeef698bf98d,
  Header994528f671ce4a979733A4970d9d4bf3,
  Groupflow51a5a35557fd406dBa19076d6811c3a8,
  HeaderTop031bfc107975485dB378231c97aefdc2,
  HeaderCb2104c4D9544c57Ae256a34f9012b9b,
  NavMenuSlugF48a087792e545cc8c3a4053349e72db,
  Title3f051cb3Fa144d8dA6e67f043f15927a,
  Title2274b08d16c84bdbBd8e42640dc5957b,
  TitleAef61128039c4f80883c4066f638ebae,
  LabelC016557cA8274821B992C40d21d7decf,
  Title7708eb80182340ceB02a1e63c17e3769,
  DefaultHeaderFfe2dd91E56b449cB268Ca249c9cc18e,
  SecondaryMarketingHero7bdcdb8a8b3745ad9719Ffff926f91d0,
  AdminPagesD5517b1c2ea147789734046b6cf794de,
  Footer0e3d36ba5fe14c518925365933845aef,
  SecondaryNav94b293ccB5a446a8A146B44c8ca28797,
  Title598a0b71C73a42f78a7f7af545a60887,
  Footer3a58d758282d46409786E576fd1b2efc,
  Navigation2ac18fdaD4364d9e9cd1548dfafe51df,
  CopyrightA2e3538b9064476aA9626f71ca2064fe,
  NavMenuSlug6fee87a6Ea7d4d5396f64be5b3cb3cf1,
  NavMenuSlug38437151Cfff4aad85dd508d43e6fd55,
  LabelCedb3851D3034fa9B29d1267b5ad7c0a,
  HomePageHeroAbf9e1afF056432eB235D5cbbeb3005a,
  PublicEventsPageAae75d21Fdb046a48ba8330ee3921424,
  DefaultLogo898dba46C1754a6991f823b95a39a06f,
  BackgroundImageE55d640d4927491eA50194d649a2a421,
  Footer14bb2a1aAa5d4ce2A56182763978cda8,
  HeaderTop43e1101e01c74852A28042c347142d49,
  Header08d9a5fb0988480588d404eb2141dcd7,
  WelcomeBack340833955dc344978fe694253097dfc4,
  NavMenuSlug829ce529Adf14030A640B90623240c03,
  TitleEdf878f232894852Ae05Eae3d0735139,
  PostSlugAddb871cF9f844c191adC366913e8c9c,
  SecondaryPage88e97e86403c48d6911354ad91970a52,
  HomeHeaderDa36af7f51c0441aBff2Bc70ced6e2be,
  HeroAbfbba9cF3b841019d34A82e34abbd2f,
  SocialLinksEacc0c03Af984eb384d81a29a90bd9f2,
  Navigation0730e74e76e4483cB0e450a8efd88835,
  LinksD7610a2cA750465c8f5fE9bdbe2f2fd2,
  SocialLinks604036b3999548b2804620bfc75b383f,
  HeaderB1d1589e0f314e479345331d4b130d5b,
  BodyD63494f7443f497380cc50dac3774575,
  NavMenuSlug7eada52f08c846bc85b3E3acb9c5f34d,
  TitleD731c24507ab4e9bA3f9D38b74d5d619
}

export default Components;