const ROUTES = {
  "/": {
    "name": "HomePageEf6a465743ea4a4b825e82bb3e33a2fb",
    "type": "HTML",
    "key": "home-page"
  },
  "/_admin/*": {
    "name": "AdminPagesD5517b1c2ea147789734046b6cf794de",
    "type": "HTML",
    "key": "admin-pages"
  },
  "/about": {
    "name": "AboutUsPageC6154e6c4c0d4773A746D098c17e3649",
    "type": "HTML",
    "key": "about-us-page"
  },
  "/events/public": {
    "name": "PublicEventsPageAae75d21Fdb046a48ba8330ee3921424",
    "type": "HTML",
    "key": "public-events-page"
  },
  "/join": {
    "name": "JoinUsPage00302df12a4445b4986cE7e9d0fe2b45",
    "type": "REFERENCE",
    "key": "join-us-page"
  },
  "/login": {
    "name": "LoginPage31818322Fff04ed68518Eeef698bf98d",
    "type": "HTML",
    "key": "login-page"
  },
  "/members/*": {
    "name": "MemberPagesC12dfeb6358c459c9b5eF875ac5f38a4",
    "type": "HTML",
    "key": "member-pages"
  },
  "/members/messages/*": {
    "name": "MessagePagesFb812ed661eb47f09fa150fcc87dc320",
    "type": "HTML",
    "key": "message-pages"
  },
  "/reset_password": {
    "name": "PasswordResetPage9b026caf9791419087501e649c7950c9",
    "type": "REFERENCE",
    "key": "password-reset-page"
  },
  "/resources": {
    "name": "ResourcesPage37564e7d2e5c4026B341786c49947cac",
    "type": "REFERENCE",
    "key": "resources-page"
  },
  "/signup/*": {
    "name": "SignupPages6e94536b45854a65B2754b20df9e3629",
    "type": "REFERENCE",
    "key": "signup-pages"
  }
};

export default ROUTES;